<template>
  <div class="content">
    <div class="head">
      <div class="left">
        <p>{{ $t('home').UserName }}: {{ userInfo.UserName }}</p>
        <span style="font-weight: bold">ID:{{ userInfo.Code }}</span>
        <span style="margin: 0 5px">{{
          lang === 'zh'
            ? userInfo.GradeName
            : lang === 'ty'
            ? userInfo.GradeNameThai
            : lang === 'en'
            ? userInfo.GradeNameEnglish
            : lang === 'jp'
            ? userInfo.GradeNameJapanese
            : lang === 'hy'
            ? userInfo.GradeNameKorea
            : userInfo.GradeNameIndonesia
        }}</span>
        <span>{{ $t('home').score }}：{{ userInfo.Credit }}</span>
      </div>
      <van-icon name="arrow" size="20" />
    </div>
    <div class="scroll">
      <div
        class="list"
        v-for="(item, index) in list"
        :key="index"
        @click="handleClick(item)"
      >
        <!-- <div
          class="item"
          style="border-top: 1px solid #273344;"
          v-if="index === 3"
        >
          <div class="left">
            <img :src="item.icon" alt="" />
            <span>{{ item.name }}</span>
          </div>
          <van-switch v-model="checked" />
        </div> -->
        <div class="item">
          <div class="left">
            <img :src="item.icon" alt="" />
            <span>{{ item.name }}</span>
          </div>
          <van-icon name="arrow" size="15" />
        </div>
      </div>
      <div class="list" @click="$router.push({ name: 'Bank' })" v-if="basicConfig.IsBank">
        <div class="item">
          <div class="left">
            <img src="../../../assets/new/bank.png" alt="" />
            <span>{{ $t('msgbank').bankCard }}</span>
          </div>
          <van-icon name="arrow" size="15" />
        </div>
      </div>
      <!-- <div class="serve" @click="$router.push({ name: 'Server' })">
        <img src="../../../assets/new/news.png" alt="" />
        <span>{{ $t('public').zxkf }}</span>
      </div> -->
      <div to="/login" class="btn" @click="Logout">
        {{ $t('home').tcdl }}
      </div>
    </div>
  </div>
</template>

<script>
import { Token } from '@/utils/mm'
import { EventBus } from '@/utils/event-bus'
import { mapGetters } from 'vuex'
import userApi from '@/api/user'
export default {
  data() {
    return {
      checked: false,
      list: [
        {
          name: this.$t('home').sfyz,
          icon: require('../../../assets/new/personal.png'),
          to: 'UserAuth'
        },
        // {
        //   name: this.$t('home').skfs,
        //   icon: require('../../../assets/new/receivables.png'),
        //   to: 'CollectMoney'
        // },
        {
          name: this.$t('home').share,
          icon: require('../../../assets/new/share.png'),
          to: 'Share'
        },
        // {
        //   name: '夜间模式',
        //   icon: require('../../../assets/new/sucerty.png'),
        //   to: ''
        // },
        {
          name: this.$t('home').kjcb,
          icon: require('../../../assets/new/cb01.png'),
          to: 'Recharge'
        },
        {
          name: this.$t('home').kjtb,
          icon: require('../../../assets/new/tb01.png'),
          to: 'With'
        },
        {
          name: this.$t('home').qbdz,
          icon: require('../../../assets/new/b.png'),
          to: 'MoneyAddr'
        },
        {
          name: this.$t('home').scwk,
          icon: require('../../../assets/new/suocang.png'),
          to: 'Lockming'
        },
        {
          name: this.$t('home').bps,
          icon: require('../../../assets/new/loss.png'),
          to: 'Detail',
          type: 'whitePaper'
        },
        {
          name: this.$t('home').xgmm,
          icon: require('../../../assets/new/c2c.png'),
          to: 'EditPassword'
        },
        {
          name: this.$t('home').xgzfmm,
          icon: require('../../../assets/new/c2c.png'),
          to: 'EditWithPassword'
        },
        {
          name: this.$t('home').gywm,
          icon: require('../../../assets/new/ieo.png'),
          to: 'Detail',
          type: 'AboutUs'
        }
      ],
      userInfo: {}
    }
  },
  computed: {
    ...mapGetters(['basicConfig', 'lang'])
  },
  watch: {
    lang() {
      this.list = [
        {
          name: this.$t('home').sfyz,
          icon: require('../../../assets/new/personal.png'),
          to: 'UserAuth'
        },
        {
          name: this.$t('home').share,
          icon: require('../../../assets/new/share.png'),
          to: 'Share'
        },

        {
          name: this.$t('home').kjcb,
          icon: require('../../../assets/new/cb01.png'),
          to: 'Recharge'
        },
        {
          name: this.$t('home').kjtb,
          icon: require('../../../assets/new/tb01.png'),
          to: 'With'
        },
        {
          name: this.$t('home').qbdz,
          icon: require('../../../assets/new/b.png'),
          to: 'MoneyAddr'
        },
        {
          name: this.$t('home').scwk,
          icon: require('../../../assets/new/suocang.png'),
          to: 'Lockming'
        },
        {
          name: this.$t('home').bps,
          icon: require('../../../assets/new/loss.png'),
          to: 'Detail',
          type: 'whitePaper'
        },
        {
          name: this.$t('home').xgmm,
          icon: require('../../../assets/new/c2c.png'),
          to: 'EditPassword'
        },
        {
          name: this.$t('home').xgzfmm,
          icon: require('../../../assets/new/c2c.png'),
          to: 'EditWithPassword'
        },
        {
          name: this.$t('home').gywm,
          icon: require('../../../assets/new/ieo.png'),
          to: 'Detail',
          type: 'AboutUs'
        }
      ]
    }
  },
  mounted() {
    this.getUserInfo()
  },
  methods: {
    async getUserInfo() {
      this.userInfo = await userApi.userInfo()
    },
    handleClick(item) {
      if (item.to === 'Detail') {
        this.$router.push({
          name: item.to,
          query: {
            type: item.type
          }
        })
      } else {
        this.$router.push({ name: item.to })
      }
    },
    Logout() {
      Token.remove()
      this.$router.push({ name: 'Login' })
      EventBus.$emit('Logout')
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  background: #17181e;
  font-size: 12px;
  .head {
    background: #17181e;
    padding: 40px 15px 20px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    p {
      font-size: 14px;
      margin-bottom: 5px;
    }
  }
  .scroll {
    height: calc(100vh - 141px);
    padding-bottom: 50px;
    overflow-y: auto;
  }
  .list {
    .item {
      display: flex;
      height: 51px;
      padding: 0 20px;
      align-items: center;
      justify-content: space-between;
      .left {
        display: flex;
        align-items: center;
      }
      img {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
    }
  }
  .serve {
    color: rgb(0, 122, 255);
    display: flex;
    align-items: center;
    padding: 5px 15px 20px;
    img {
      margin-right: 5px;
      width: 30px;
      height: 30px;
    }
  }
  .btn {
    margin: 20px auto;
    background: #8e1473;
    color: #fff;
    width: 204px;
    height: 36px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
