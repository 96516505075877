<template>
  <div class="container">
    <header>
      <img src="@/assets/new/head.png" alt="" @click="show = true" />
      <div class="right">
        <van-popover
          v-model="showPopover"
          theme="dark"
          placement="bottom-end"
          :actions="actions"
          @select="onSelect"
        >
          <template #reference>
            <div class="lang" @click="showPopover = !showPopover">
              <span>{{ nowLang }}</span>
            </div>
          </template>
        </van-popover>
        <van-badge :dot="unReadMsg">
          <img
            src="@/assets/new/message.png"
            alt=""
            @click="$router.push({ name: 'Message' })"
          />
        </van-badge>
      </div>
    </header>
    <van-swipe
      class="swipe-container"
      :autoplay="3000"
      height="150"
      indicator-color="#ffffff"
    >
      <van-swipe-item v-for="(item, index) in rotationPics" :key="index">
        <img :src="item.ImgUrl" alt="" />
      </van-swipe-item>
    </van-swipe>
    <!-- <div class="notice">
      <img src="@/assets/new/notice.png" alt="" />
      <div @click="handleClick">{{ CommonNotice.Title }}</div>
    </div> -->
    <van-notice-bar
      color="#fff"
      background="#17181e"
      left-icon="volume-o"
      mode="link"
      :scrollable="false"
      @click="$router.push({ name: 'Notice' })"
    >
      <van-swipe
        vertical
        class="notice-swipe"
        :autoplay="3000"
        :touchable="false"
        :show-indicators="false"
      >
        <van-swipe-item v-for="(item, index) in CommonNotice" :key="index">{{
          item.Title
        }}</van-swipe-item>
      </van-swipe>
    </van-notice-bar>
    <ProductList />
    <div class="box">
      <div class="left" @click="$router.push({ name: 'Recharge' })">
        <div class="info">
          <div class="title">{{ $t('home').kjmb }}</div>
          <p>{{ $t('home').zc }}</p>
        </div>
        <img src="@/assets/new/legal_card.png" alt="" />
      </div>
      <div class="right">
        <div class="item" @click="$router.push({ name: 'Lockming' })">
          <img src="@/assets/new/news.png" alt="" />
          <span>{{ $t('home').scwk }}</span>
        </div>
        <div class="item" @click="$router.push({ name: 'NewBe' })">
          <img src="@/assets/new/gang1.png" alt="" />
          <span>{{ $t('home').sgxb }}</span>
        </div>
      </div>
    </div>
    <div class="banner-box" @click="getCompanyInfo">
      <img src="@/assets/new/banner.png" class="banner" alt="" />
      <div class="img-name">
        <h3>{{ $t('home').aqkx }}</h3>
        <p>{{ $t('home').aqkx1 }}</p>
      </div>
    </div>
    <div class="btn-box">
      <div @click="goServe" class="serve">
        {{ $t('public').zxkf }}
      </div>
      <router-link tag="div" :to="{ name: 'Leverage' }" class="serve">
        {{ $t('home').hy }}
      </router-link>
    </div>

    <ProductColumnList />
    <TabBar />
    <van-popup
      v-model="show"
      position="left"
      :style="{ width: '244px', height: '100%' }"
    >
      <User />
    </van-popup>
    <!-- 公告 -->
    <van-popup v-model="noticeShow" round :style="{ width: '80%' }">
      <div class="noticeInfo">
        <div class="top">
          <img src="@/assets/new/home_notice.png" />
          <span>{{ $t('msgbank').noticeTitle }}</span>
        </div>
        <div class="title">{{ noticeTitle }}</div>
        <div class="content">{{ noticeContent }}</div>
        <div class="lines"></div>
        <div class="btns" @click="noticeShow = false">
          {{ $t('public').sure }}
        </div>
      </div>
    </van-popup>
    <!-- 站内信 -->
    <van-popup
      v-model="messageShow"
      round
      :style="{ width: '80%' }"
      :close-on-click-overlay="false"
    >
      <div class="noticeInfo">
        <div class="top">
          <img src="@/assets/new/home_notice.png" />
          <span>{{ $t('msgbank').msgTitle }}</span>
        </div>
        <div class="title">{{ $t('msgbank').msgContent }}</div>
        <div class="lines"></div>
        <div class="btns" @click="$router.push({ name: 'Message' })">
          {{ $t('msgbank').msgBtn }}
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { IsreadSysNotice, showName } from '@/utils/validate'
import userApi from '@/api/user'
import ProductList from './components/ProductList'
import User from './components/User'
import TabBar from '@/components/tabBarNew'
import ProductColumnList from './components/ProductColumnList'
import { Token } from '@/utils/mm'

export default {
  components: {
    ProductList,
    ProductColumnList,
    TabBar,
    User
  },
  data() {
    return {
      unReadMsg: false,
      noticeShow: false,
      messageShow: false,
      noticeTitle: '',
      noticeContent: '',
      rotationPics: [],
      show: false,
      showPopover: false,
      CommonNotice: [],
      actions: [
        { text: 'English' },
        { text: '中文' },
        { text: 'ภาษาไทย' },
        { text: '日本語' },
        { text: '한국인' },
        { text: 'Indonesia' },
        { text: '繁体中文' },
        //加着几个语言德语，法语，阿拉伯语，俄语，   西班牙语
        { text: 'Deutsch' }, //德语
        { text: 'Français' }, //法语
        { text: 'العربية' }, //阿拉伯语
        { text: 'Русский язык' }, //俄语
        { text: 'Español' } //西班牙语
      ]
    }
  },
  watch: {
    lang(val) {
      // const CommonNotice = this.basicConfig.CommonNotice
      // if (CommonNotice && CommonNotice.length) {
      //   CommonNotice.some((item) => {
      //     if (item.LanguageType === showName(val)) {
      //       this.CommonNotice = item
      //       return true
      //     }
      //   })
      // }
      this.getNoticeList(showName(val))
    }
  },
  computed: {
    ...mapGetters(['basicConfig', 'lang', 'connection']),
    nowLang() {
      return this.lang === 'zh'
        ? '中文'
        : this.lang === 'en'
        ? 'English'
        : this.lang === 'ty'
        ? 'ภาษาไทย'
        : this.lang === 'jp'
        ? '日本語'
        : this.lang === 'hy'
        ? '한국인'
        : this.lang === 'ft'
        ? '繁体中文'
        : this.lang === 'de'
        ? 'Deutsch'
        : this.lang === 'fr'
        ? 'Français'
        : this.lang === 'ar'
        ? 'العربية'
        : this.lang === 'ru'
        ? 'Русский язык'
        : this.lang === 'es'
        ? 'Español'
        : 'Indonesia'
    }
  },

  mounted() {
    this.getUserMsgUnRead()
    const LanguageSelect = this.basicConfig.LanguageSelect.join(',')
    if (LanguageSelect.indexOf('Chinese') === -1) {
      this.actions.splice(1, 1)
    }
    if (LanguageSelect.indexOf('English') === -1) {
      this.actions.splice(0, 1)
    }
    this.getLang(localStorage.getItem('language'))
    const lang = showName(this.lang)
    this.$nextTick(() => {
      setTimeout(() => {
        let PopupNotices = this.basicConfig.PopupNotices
        // if (PopupNotices && IsreadSysNotice()) {
        if (PopupNotices) {
          PopupNotices.some((item) => {
            if (item.LanguageType === lang && item.Content) {
              // this.$dialog.alert({
              //   confirmButtonText: this.$t('public').sure,
              //   title: item.Title,
              //   message: item.Content
              // })
              this.noticeShow = true
              this.noticeTitle = item.Title
              this.noticeContent = item.Content
              return true
            }
          })
        }
      }, 500)

      const CommonNotice = this.basicConfig.CommonNotice
      if (CommonNotice && CommonNotice.length) {
        CommonNotice.some((item) => {
          if (item.LanguageType === lang) {
            this.CommonNotice = item
            return true
          }
        })
      }
    })
  },
  methods: {
    async getCompanyInfo() {
      this.$router.push({
        name: 'Detail',
        query: {
          type: 'AboutUs'
        }
      })
    },
    async getUserInfo() {
      const userInfo = await userApi.userInfo()
      return userInfo
    },
    async getUserMsgUnRead() {
      const data = await userApi.getUserMessageList({ isRead: false })
      if (data.Data.length > 0) {
        this.messageShow = true
        this.unReadMsg = true
      } else {
        this.unReadMsg = false
      }
    },
    async getNoticeList(LanguageType) {
      const data = await userApi.notices({
        LanguageType: LanguageType
      })
      this.CommonNotice = data.Data
    },
    async goServe() {
      let str = this.basicConfig.ServiceUrl
      if (Token.get()) {
        const res = await this.getUserInfo()
        let userInfo = {
          name: res.UserName,
          uid: res.Code
        }
        if (str.indexOf('?') === -1) {
          str += '?userInfo=' + JSON.stringify(userInfo)
        } else {
          str += '&userInfo=' + JSON.stringify(userInfo)
        }
      }
      str += `&lang=${this.lang === 'zh' ? 'zh-CN' : 'en-US'}`
      location.href = str
    },
    async onSelect(action) {
      let lang = ''
      let PortType = 'APP'
      let LanguageType = ''
      // console.log(action)
      if (action.text === '中文') {
        lang = 'zh'
      } else if (action.text === 'English') {
        lang = 'en'
      } else if (action.text === 'ภาษาไทย') {
        lang = 'ty'
      } else if (action.text === '日本語') {
        lang = 'jp'
      } else if (action.text === '한국인') {
        lang = 'hy'
      } else if (action.text === 'Indonesia') {
        lang = 'yn'
      } else if (action.text === '繁体中文') {
        lang = 'ft'
      } else if (action.text === 'Deutsch') {
        lang = 'de'
      } else if (action.text === 'Français') {
        lang = 'fr'
      } else if (action.text === 'العربية') {
        lang = 'ar'
      } else if (action.text === 'Русский язык') {
        lang = 'ru'
      } else if (action.text === 'Español') {
        lang = 'es'
      }

      this.$i18n.locale = lang
      let basicConfig = this.basicConfig
      // console.log(lang)
      this.$store.commit('SET_LANG', lang)
      let title = ''
      if (lang === 'zh') {
        title = basicConfig.CompanyName
        LanguageType = 'Chinese'
      } else if (lang === 'en') {
        title = basicConfig.CompanyNameEnglish
        LanguageType = 'English'
      } else if (lang === 'ty') {
        title = basicConfig.CompanyNameThai
        LanguageType = 'Thai'
      } else if (lang === 'jp') {
        title = basicConfig.CompanyNameJapanese
        LanguageType = 'Japanese'
      } else if (lang === 'hy') {
        title = basicConfig.CompanyNameKorea
        LanguageType = 'Korea'
      } else if (lang === 'yn') {
        title = basicConfig.CompanyNameIndonesia
        LanguageType = 'Indonesia'
      } else if (lang === 'ft') {
        LanguageType = 'Traditional'
      } else if (lang === 'de') {
        LanguageType = 'German'
      } else if (lang === 'fr') {
        LanguageType = 'French'
      } else if (lang === 'ar') {
        LanguageType = 'Arabic'
      } else if (lang === 'ru') {
        LanguageType = 'Russian'
      } else if (lang === 'es') {
        LanguageType = 'Spanish'
      }
      this.rotationPics = await userApi.rotationPics({ LanguageType, PortType })
      document.title = title
      localStorage.setItem('language', lang)

      // this.$router.go(0)
    },
    async getLang(lang) {
      let LanguageType = ''
      let PortType = 'APP'
      if (lang === 'zh') {
        LanguageType = 'Chinese'
      } else if (lang === 'en') {
        LanguageType = 'English'
      } else if (lang === 'ty') {
        LanguageType = 'Thai'
      } else if (lang === 'ft') {
        LanguageType = 'Traditional'
      } else if (lang === 'jp') {
        LanguageType = 'Japanese'
      } else if (lang === 'hy') {
        LanguageType = 'Korea'
      } else if (lang === 'yn') {
        LanguageType = 'Indonesia'
      } else if (lang === 'de') {
        LanguageType = 'German'
      } else if (lang === 'fr') {
        LanguageType = 'French'
      } else if (lang === 'ar') {
        LanguageType = 'Arabic'
      } else if (lang === 'ru') {
        LanguageType = 'Russian'
      } else if (lang === 'es') {
        LanguageType = 'Spanish'
      }
      this.rotationPics = await userApi.rotationPics({ LanguageType, PortType })
      this.getNoticeList(LanguageType)
    },
    handleClick() {
      this.$router.push({
        name: 'Detail',
        query: {
          title: this.CommonNotice.Title,
          info: this.CommonNotice.Content
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.notice-swipe {
  height: 40px;
  line-height: 43px;
}
.container {
  min-height: 100vh;
  font-size: 14px;
  padding-bottom: 51px;
  & /deep/ .van-popup--left {
    top: 0;
    height: calc(100vh - 50px) !important;
    transform: translate3d(0, 0, 0);
  }
  header {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    height: 48px;
    box-sizing: border-box;
    background: #17181e;
    img {
      width: 25px;
      height: 25px;
    }
    .right {
      display: flex;
      justify-content: right;
      align-items: center;
      .lang {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 28px;
        margin-right: 10px;
      }
      img {
        width: 17px;
        height: 17px;
      }
    }
  }
  .swipe-container {
    img {
      height: 150px;
      width: 100vw;
    }
    & /deep/ .van-swipe__indicator {
      width: 8px;
      height: 8px;
    }
  }
  .notice {
    background: #17181e;
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    font-size: 12px;
    margin-bottom: 1px;
    img {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
  }
  .box {
    display: flex;
    height: 85px;
    padding: 5px 10px;
    .left {
      flex: 1;
      height: 85px;
      border-radius: 4px;
      background: #17181e;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 10px;
      img {
        width: 50px;
      }
      .info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 12px;
        color: #8897ad;
        .title {
          font-size: 16px;
          font-weight: bold;
          color: #fff;
          margin-bottom: 10px;
        }
      }
    }
    .right {
      margin-left: 5px;
      .item {
        width: 130px;
        height: 40px;
        border-radius: 4px;
        background: #17181e;
        display: flex;
        align-items: center;
        padding: 0 10px;
        font-size: 12px;
        img {
          width: 25px;
          margin-right: 10px;
        }
        &:nth-of-type(1) {
          margin-bottom: 5px;
          text-align: left;
        }
      }
    }
  }
  .btn-box {
    display: flex;
    justify-content: space-around;
    margin: 0 10px 10px;
    .serve {
      width: 45%;
      height: 40px;
      border-radius: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      background: #17181e;
      font-weight: bold;
      color: #fff;
      &:nth-of-type(2) {
        background: #8e1473;
      }
    }
  }
  .banner-box {
    position: relative;
    .banner {
      margin: 10px;
      width: 355px;
      height: 70px;
    }
    .img-name {
      position: absolute;
      top: 25px;
      left: 40px;
      font-size: 12px;
      h3 {
        font-size: 16px;
        margin: 0 0 5px;
      }
    }
  }
}
.noticeInfo {
  color: #17181e;
  .top {
    display: flex;
    align-items: center;
    background: #8e1473;
    height: 80px;
    padding: 0 10px;
    img {
      width: 80px;
      height: 64px;
    }
    span {
      width: 45%;
      text-align: center;
      font-size: 20px;
      font-weight: bold;
      color: #fff;
    }
  }
  .title {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    margin: 20px 0;
  }
  .content {
    margin: 0 20px;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 20px;
  }
  .lines {
    height: 1px;
    background: #d1d1d1;
    margin-bottom: 15px;
  }
  .btns {
    text-align: center;
    color: #e143ac;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 15px;
  }
}
</style>
